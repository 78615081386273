import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IExpense } from '../../dtos/master.dto';
import { fetchExpenseMaster } from '../master.action';

export interface MasterState {
    expenses: IExpense[];
    masterStatus: 'idle' | 'loading' | 'failed';
}

const initialState: MasterState = {
    expenses: [],
    masterStatus: 'idle',
};

export const getExpenseMaster = createAsyncThunk(
    'expenseMaster',
    async (token: string) => {
        return await fetchExpenseMaster(token);
    }
);

export const masterSlice = createSlice({
    name: 'assets',
    initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getExpenseMaster.pending, (state) => {
                state.masterStatus = 'loading';
            }).addCase(getExpenseMaster.fulfilled, (state, action) => {
                state.expenses = action.payload || [];
                state.masterStatus = 'idle';
            }).addCase(getExpenseMaster.rejected, (state) => {
                state.masterStatus = 'failed';
            });
    },
});

export default masterSlice.reducer;
