import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITransaction } from '../../dtos/dto';
import { fetchTransactions } from '../action';

export interface TransactionState {
    transactions: ITransaction[];
    transactionStatus: 'init' | 'idle' | 'loading' | 'failed';
}

const initialState: TransactionState = {
    transactions: [],
    transactionStatus: 'init',
};

export const updateTransactionList = createAsyncThunk(
    'transactions',
    async (params: any) => {
        const { token, page } = params;
        return await fetchTransactions(token, page);
    }
);

export const transactionSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        filterTransactions: (state, action) => {
            state.transactions = state.transactions.filter( x => x.id !== action.payload);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateTransactionList.pending, (state) => {
                state.transactionStatus = 'loading';
            })
            .addCase(updateTransactionList.fulfilled, (state, action) => {
                state.transactions = action.payload || [];
                state.transactionStatus = 'idle';
            })
            .addCase(updateTransactionList.rejected, (state) => {
                state.transactionStatus = 'failed';
            });
    },
});

export const { filterTransactions } = transactionSlice.actions

export default transactionSlice.reducer;
