import axios, { AxiosResponse } from "axios";
import { API_BASE_URL } from "./const";

axios.interceptors.response.use((res: AxiosResponse): AxiosResponse => {
    return res.data;
});

export class APIRequest {
    private static _instance: APIRequest = new APIRequest();

    constructor() {
        if (APIRequest._instance) {
          throw new Error('Use DataService.instance');
        }
        APIRequest._instance = this;
    }

    static get instance(): APIRequest {
        return APIRequest._instance;
    }

    get = async (url: string, accessToken: string = '', config = {}) => {
        url = this.addTimestampToUrl(API_BASE_URL + url);
        return axios.get(url, { headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            Authorization: 'Bearer ' + accessToken,
            ...config
        }}).then(res => res).catch(error => ({ status: 'failed', ...error?.response?.data }));
    }

    post<T, R>( url: string, body: T, accessToken: string = '', config = {} ): Promise<any> {
        url = this.addTimestampToUrl(API_BASE_URL + url);
        return axios.post(url, JSON.stringify(body), { headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            Authorization: 'Bearer ' + accessToken,
            ...config
        }}).then(res => res).catch(error => ({ status: 'failed', ...error?.response?.data }));
    }

    delete = async (url: string, accessToken: string = '', config = {}) => {
        url = this.addTimestampToUrl(API_BASE_URL + url);
        return axios.delete(url, { headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            Authorization: 'Bearer ' + accessToken,
            ...config
        }}).then(res => res).catch(error => error?.response?.data);
    }

    private addTimestampToUrl = (url: string): string => {
        if (url) {
          let new_url = new URL(url);
          let search_params = new_url.searchParams;
          search_params.set('dt', new Date().valueOf().toString());
          new_url.search = search_params.toString();
          return new_url.toString();
        }
        return '';
    };

    getIp = async () => {
        const ip: any = await axios.get('https://api.ipify.org?format=json').catch(err => null);
        if (ip?.ip !== null) {
            sessionStorage.setItem('ip', ip.ip);
        }
    }
}