import { APIRequest } from "../utils/http.service"

export const fetchTransactions = async (token: string, page: number) => {
    const res = await APIRequest.instance.get('/transactions/' + page, token);
    if ( res && res?.status !== 'failed' && res?.length !== 0 ) {
        return res;
    }
}

export const fetchMutualFund = async (token: string) => {
    const res = await APIRequest.instance.get('/assets/mutual-funds', token);
    if ( res && res?.status !== 'failed' && res?.length !== 0 ) {
        return res;
    }
    return [];
}

export const fetchAccounts = async (token: string) => {
    const res = await APIRequest.instance.get('/assets/accounts', token);
    if ( res && res?.status !== 'failed' && res?.length !== 0 ) {
        return res;
    }
    return [];
}

export const fetchAccountTransactions = async (token: string, accId: string, page: number) => {
    const res = await APIRequest.instance.get(`/transactions/${accId}/${page}`, token);
    if ( res && res?.status !== 'failed' && res?.length !== 0 ) {
        return res;
    }
    return [];
}

export const fetchUserProfile = async (token: string) => {
    const res = await APIRequest.instance.get('/user/profile', token);
    if ( res && res?.status !== 'failed' && res?.length !== 0 ) {
        return res;
    }
}


export const updateUserProfile = async (token: string, key: string, value: any) => {
    return await APIRequest.instance.post('/user/profile', {key, value}, token);
}
