import React, { createContext, useContext, useMemo } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { APIRequest } from "../utils/http.service";

interface IUser {
    id: string;
    firstname: string;
    lastname: string;
    initials: string;
    email: string;
}

interface IAuthContext {
    token: string;
    user: IUser|null;
    logIn: Function;
    logOut: Function;
}

const intialValue: IAuthContext = {
    token: '',
    user: null,
    logIn: () => { return },
    logOut: () => { return },
}

const AuthContext = createContext<IAuthContext>(intialValue);

export const AuthProvider = ({ children }: any) => {

    const [token, setToken] = useLocalStorage('token', null);
    const [user, setUser] = React.useState<IUser|null>(null);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (token) {
            validateLogin();
        }
    }, [token]);

    const validateLogin = async (tokenData: string = '') => {
        const res = await APIRequest.instance.get('/auth/sign-in', tokenData || token);
        if (res?.id) {
            const { id, firstname, lastname, email } = res;
            setUser({ id, firstname, lastname, email, initials: firstname[0] + lastname[0]});
            return res;
        } else if (res.statusCode === 401) {
            logOut();
        }
    }

    // call this function when you want to authenticate the user
    const logIn = async (token: string) => {
        setToken(token);
        return navigate('/home');
    };

    // call this function to sign out logged in user
    const logOut = () => {
        setUser(null);
        setToken(null);
        return navigate('/')
    };

    const value = useMemo(
        () => ({
            token, user, logIn, logOut
        }),
        [user]
    );
    return <AuthContext.Provider value={ value }> { children } </AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};