import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import RouteConfig from './Routes';
import { PageLoader } from './pages/utils/components/Loader';
import { Provider } from 'react-redux';
import store from './redux/store';
import { Backdrop, CircularProgress } from '@mui/material';

function App() {

	return <BrowserRouter>
				<Provider store={store}>
					<AuthProvider>
						<Suspense fallback={<PageLoader />}>
							<RouteConfig />
						</Suspense>
					</AuthProvider>
				</Provider>
			</BrowserRouter>
}

export default App;
