export const API_BASE_URL = process.env.REACT_APP_API_URL
export const ASSET_BASE_URL = process.env.REACT_ASSET_BASE_URL

export const BASE_COLOR = {
    CopperRed: '#d47444', //: A strong accent color that can be used for call-to-action buttons or highlights1.
    Jasmine: '#f8d47c', //: A lighter accent color that can provide a sense of optimism and energy1.
    LiverOrgan: '#6b3418', //: A darker shade that can be used for text or to create depth1.
    CopperPenny: '#b46c60', //: A muted red tone that can be used for subtle accents1.
    PinkLavender: '#dbacc3', //: A soft, calming color that can be used for backgrounds or to soothe the user’s eyes1.
    Arsenic: '#3c3f4c' ///: A strong, neutral color that can be used for text and to convey seriousness and stability1.
}

export const EXPENSES = [
    'Insurance', 'Travel', 'Utility Payment', 'Bill Payment', 'Repairing', 'Interest', 'Gas', 'Education', 'Gift', 'Salaries'
]