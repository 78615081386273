import { APIRequest } from "../utils/http.service";

export const fetchMutualFundMaster = async (token: string, query: string) => {
    const res = await APIRequest.instance.get('/master/mutual-funds?search=' + query, token);
    if (res?.length !== 0 ) {
        return res;
    }
}

export const fetchAccountVariantMaster = async (token: string, query: string) => {
    const res = await APIRequest.instance.get('/master/account-variants?search=' + query, token);
    if (res?.length !== 0 ) {
        return res;
    }
}

export const fetchBankMaster = async (token: string, query: string) => {
    const res = await APIRequest.instance.get('/master/banks?search=' + query, token);
    if (res?.length !== 0 ) {
        return res;
    }
}

export const fetchCurrencyMaster = async (query: string) => {
    const res = await APIRequest.instance.get('/master/currencies?search=' + query);
    if (res?.length !== 0 ) {
        return res;
    }
}

export const fetchExpenseMaster = async (token: string) => {
    const res = await APIRequest.instance.get('/master/expenses', token);
    if (res?.length !== 0 ) {
        return res;
    }
}