import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITransaction } from '../../dtos/dto';
import { fetchAccountTransactions } from '../action';

interface IDetails {
    status: 'init' | 'idle' | 'loading' | 'failed';
    data: ITransaction[]
}

export interface AccountState {
    accountDetails: { [key: string]: IDetails };
    status: 'idle' | 'loading' | 'failed';
}

const initialState: AccountState = {
    accountDetails: {},
    status: 'idle'
};

interface IUpdateAccountDetails {
    token: string;
    accId: string;
    page: number;
}

export const updateAccountDetails = createAsyncThunk(
    'accountDetails',
    async ({ token, accId, page} : IUpdateAccountDetails) => {
        const data = await fetchAccountTransactions(token, accId, page);
        return { data, accountId: accId};
    }
);

export const accountDetailSlice = createSlice({
    name: 'accountDetails',
    initialState,
    reducers: {
        filterAccount: (state, action) => {
            // state.accounts = state.accounts.filter( x => x.id !== action.payload);
        },
        updateAccount: (state, action) => {
            // state.accounts = state.accounts.map( x => x.id !== action.payload.id ? x : action.payload);
        },
        addAccount: (state, action) => {
            // state.accounts = state.accounts.concat([action.payload]);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateAccountDetails.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateAccountDetails.fulfilled, (state, action) => {
                if (state.accountDetails[action.payload.accountId]) {
                    state.accountDetails[action.payload.accountId] = {
                        status: 'idle',
                        data: state.accountDetails[action.payload.accountId].data.concat(action.payload.data)
                    }
                } else {
                    state.accountDetails[action.payload.accountId] = {
                        status: 'idle',
                        data: action.payload.data
                    }
                }
            })
            .addCase(updateAccountDetails.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

export const { addAccount, updateAccount, filterAccount } = accountDetailSlice.actions

export default accountDetailSlice.reducer;
