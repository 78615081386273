import { configureStore } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux';
import transactionReducer from './slices/transaction.slice'
import accountReducer from './slices/account.slice'
import accountDetailReducer from './slices/accountDetails.slice'
import masterReducer from './slices/master.slice';

const store = configureStore({
    reducer: {
        transactions: transactionReducer,
        assets: accountReducer,
        accountDetails: accountDetailReducer,
        master: masterReducer
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
export const useAppSelector = useSelector.withTypes<RootState>()

export default store;